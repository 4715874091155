import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "supported-devices--operating-systems"
    }}>{`Supported Devices & Operating Systems`}</h1>
    <hr></hr>
    <p>{`The mobile app is supported on the following devices:`}</p>
    <ul>
      <li parentName="ul">{`Android`}</li>
      <li parentName="ul">{`Apple `}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Device Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`OS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Android`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Android 7 Nougat or higher`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Apple`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iOS 14 or higher`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`To find compatible models of iPhone with certain iOS versions, please refer to Apple's `}<a parentName="p" {...{
        "href": "https://support.apple.com/guide/iphone/models-compatible-with-ios-17-iphe3fa5df43/17.0/ios/17.0"
      }}>{`iPhone User Guide`}</a>{` to learn more.`}</p>
    <p>{`To learn about Android's OS releases, please refer to their `}<a parentName="p" {...{
        "href": "https://developer.android.com/about/versions"
      }}>{`All Android Releases`}</a>{` page for more information. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      